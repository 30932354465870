
.home_app {
    width: 100vw;
    height: calc(100svh - 7rem);
}

.home_img_1{
    width: 100vw;
    height: 100svh;
    position: absolute;
    top: 0;
    z-index: -1;
    background-image: url('/public/assets/img/home/img_1.webp');
    background-position: center 0rem;
    background-size: cover;
}


.text_home_1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
}

.view_more_1_home{
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: initial;
    background-color: #878787;
    color: white;
    padding: 10px;
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 18px;
    transition: 0.1s;
}

.view_more_1_home:hover{
    border: 1px solid white;
}



.demande_devis{
    position: fixed;
    bottom: 1%;
    right: 0%;
    cursor: pointer;
    z-index: 1;
    width: 10rem;
}


.home_INSPECTION{
    position: relative;
    width: 100%;
    height: auto;
    background-color: white;
}

.INSPECTION_title{
    width: 60%;
    margin: auto;
    text-align: center;
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 30px;
    margin-top: 3rem; 
    position: relative;
}

.INSPECTION_title span{
    background-color: #FED576;
    width: 4rem;
    height: 0.4rem;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home_INSPECTION_Section{
    width: 90%;
    height: 80svh;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 2rem;
    flex-wrap: wrap;
}


.pro_de_mer{
    width: 30%;
    height: 90%;
    background-image: url('/public/assets/img/home/pro_la_mer.webp');
    background-position: center;
    background-size: cover;
    position: relative;
}

.pro_de_mer_text{
    width: 67%;
    height: 100%;
}


.pro_de_mer h1{
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 30px;
    color: #FED576;
    position: absolute;
    bottom: 5%;
    left: 7%;
}


.pro_de_mer_text_p_1, .pro_de_mer_text_p_2{
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 22px;
    margin-top: 3rem;
    line-height: 38px;
}

.view_more_inspection{
    margin-top: 2rem;
    cursor: pointer;
    background-color: #5B59CE;
    color: white;
    padding: 10px;
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 18px;
    transition: 0.1s;
}


.home_Controle_qualite{
    position: relative;
    width: 100%;
    height: 100svh;
    background-color: #2E2D68;
    display: flex;
    align-items: center;
}



.home_bg_controle_qu{
    width: 100%;
    height: 80%;
    background-image: url('/public/assets/img/home/controle_qual_bg.png');
    background-position: center;
    background-size: cover;
    position: relative;
}

.home_bg_controle_qu h1{
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 40px;
    color: white;
    margin-top: 6rem;
    margin-left: 4rem;
}

.home_bg_controle_qu p{
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 25px;
    line-height: 45px;
    color: white;
    width: 60%;
    margin: auto;
    margin-left: 4rem;
    margin-top: 2rem;
}

.btn_engagement{
    width: 14rem;
    margin-left: 6rem;
    margin-top: 2rem;
    cursor: pointer;
    background-color: #5B59CE;
    color: #FED576;
    padding: 10px;
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 22px;
    transition: 0.1s;
    border-radius: 20px;
}

.btn_engagement:hover{
    border: 2px solid #FED576;

}


.home_Conseil{
    width: 100%;
    height: auto;
    position: relative;
}


.home_Conseil_div_1{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.home_Conseil_div_1 .div_1_co{
    width: 55%;
    height: auto;
    padding-left: 4rem;
}

.home_Conseil_div_1 .div_1_co h1{
    color: #D0B042;
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 45px;
    letter-spacing: 12px;
    text-transform: uppercase;
}

.home_Conseil_div_1 .div_1_co p{
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 25px;
    line-height: 45px;
    
}


.serdine_home{
    width: 40%;
}


.title_conseil_2{
    text-transform: uppercase;
    text-align: center;
    margin-top: 4rem;
    font-family: 'tenby_five';
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 5px;
}


.title_p_conseil_2{
    width: 80%;
    margin: auto;
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 22px;
    line-height: 45px;
}


.doctor_bg{
    width: 100%;
    height: 24rem;
    margin-top: 3rem;
    background-image: url('/public/assets/img/home/doctor.webp');
    background-position: center;
    background-size: cover;
    position: relative;
}

.boat_bg_1{
    width: 100%;
    height: 24rem;
    margin-top: 3rem;
    background-image: url('/public/assets/img/home/boat_1.png');
    background-position: center;
    background-size: cover;
    position: relative;
}

.boat_bg_2{
    width: 100%;
    height: 24rem;
    margin-top: 3rem;
    background-image: url('/public/assets/img/home/boat_2.png');
    background-position: center;
    background-size: cover;
    position: relative;
}



@media screen and (max-width: 860px) {

    .home_img_1{
        width: 100vw;
        height: 100svh;
        position: absolute;
        top: 0;
        z-index: -1;
        background-image: url('/public/assets/img/home/img_1_mobile.webp');
        background-position: 10% 0rem;
        background-size: cover;
    }

    .text_home_1{
        width: 92%;
    }

    .view_more_1_home{
        top: 59%;
        width: 30vw;
    }

    .demande_devis{
        position: absolute;
        right: 0rem;
        width: 8rem;
    }
    
    .home_INSPECTION{
        height: auto;
    }


    .home_INSPECTION_Section{
        width: 95%;
        height: auto;
    }

    .pro_de_mer{
        width: 100%;
        height: 100vw;
    }

    .pro_de_mer_text{
        width: 100%;
    }

    .pro_de_mer_text_p_1, .pro_de_mer_text_p_2{
        font-size: 19px;
        text-align: justify;
    }

    .view_more_inspection{
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .home_Controle_qualite{
        height: auto;
    }

    .home_bg_controle_qu{
        height: auto;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .home_bg_controle_qu h1{
        margin-left: auto;
        text-align: center;
        margin-top: 3rem;
    }

    .home_bg_controle_qu p {
        font-family: 'helvetica';
        font-weight: 200;
        font-size: 20px;
        line-height: 38px;
        color: white;
        width: 95%;
        text-align: justify;
        margin: auto;
        /* margin-left: 4rem; */
        margin-top: 1rem;
    }

    .btn_engagement{
        width: 12rem;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .home_Conseil_div_1{
        flex-wrap: wrap;
    }

    .home_Conseil_div_1 .div_1_co{
        width: 100%;
        padding-left : 0;
    }


    .home_Conseil_div_1 .div_1_co h1{
        font-size: 25px;
        letter-spacing: 2px;
        text-align: center;
    }

    .home_Conseil_div_1 .div_1_co p {
        font-family: 'helvetica';
        font-weight: 200;
        font-size: 18px;
        line-height: 34px;
        text-align: justify;
        width: 90%;
        margin: auto;
    }

    .serdine_home {
        width: 90%;
        margin: auto;
        margin-top: 1rem;
    }

    .title_p_conseil_2 {
        width: 90%;
        margin: auto;
        font-family: 'helvetica';
        font-weight: 200;
        font-size: 20px;
        line-height: 37px;
        text-align: justify;
    }

    .doctor_bg{
        background-position: 80%;
    }


}