.service_div_1{
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 2.5rem;
}

.service_div_1 img{
    width: 30%;
}