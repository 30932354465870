.about_div_1{
    width: 80%;
    height: auto;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


.about_div_1 img{
    width: 35%;
}

.about_div_1 p{
    width: 64%;
    font-family: 'helvetica';
    font-weight: 200;
    font-size: 22px;
    line-height: 40px;
}




@media screen and (max-width: 860px) {

    .about_div_1{
        width: 90%;
    }

    .about_div_1 img {
        width: 85%;
        margin: auto;
    }

    .about_div_1 p {
        width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
    }



}