.Footer{
    width: 100%;
    height: auto;
}


.Footer .footer_logo{
    width: 15rem;
    display: block;
    margin: auto;
}


.Footer .footer_menu{
    display: flex;
    width: 50%;
    height: auto;
    margin: auto;
    justify-content: space-between;
    margin-top: 3rem;
    font-family: Helvetica, sans-serif;
    font-weight: 100;
}

.Footer .footer_menu h3{
    cursor: pointer;
    font-size: 1.3vw;
}

.Footer .footer_menu h3:hover{
    color: #D0B042;
}

.Footer .footer_social_media{
    display: flex;
    width: 15%;
    height: auto;
    margin: auto;
    justify-content: space-between;
    margin-top: 2rem;
}

.Footer .footer_social_media img{
    width: 2.5vw;
    cursor: pointer;
}

.Footer .footer_copyright{
    text-align: center;
    margin-top: 1rem;
    font-family: Helvetica, sans-serif;
    font-weight: 100;
}





@media screen and (max-width: 860px) {

    .Footer .footer_menu {
        width: 100%;
        justify-content: space-between;
        display: block;
        text-align: center;
    }

    .Footer .footer_menu h3 {
        font-size: 5.3vw;
        margin-top: 1rem;
    }


    .Footer .footer_social_media img {
        width: 8.5vw;
        cursor: pointer;
    }

    .Footer .footer_social_media {
        width: 40%;
    }
}