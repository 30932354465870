.starts_control{
    width: 100%;
    height: 26rem;
    margin-top: 3rem;
    background-image: url('/public/assets/img/starts_control.png');
    background-position: center;
    background-size: cover;
    position: relative;
}



@media screen and (max-width: 860px) {

    .starts_control{
        
        background-position: 28%;


    }


    .Engagement_Qualite .title_p_conseil_2{
        text-align: center;
    }
}