.Header{
    width: 100%;
    height: 7rem;
    background-color: #2E2D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Header .logo{
    width: 240px;
    cursor: pointer;
}

.Header .menu{
    width: auto;
    display: flex;
    gap: 8vw;
    color: white;
    margin: auto;
    text-transform: uppercase;
}

.Header .menu h2{
    cursor: pointer;
}

.Header .menu h2:hover{
    color: #D0B042;
}

.Header .flags{
    display: flex;
    gap: 0.5rem;
    margin-right: 2.5rem;
}

.Header .flags img{
    width: 1.6rem;
}

.Header .bars{
    display: none;
}

#menu{
    display: none;
}


@media screen and (max-width: 860px) {
    .Header .menu, .Header .flags {
        display: none;
    }

    .Header .bars{
        display: block;
        width: 2.5rem;
        margin-right: 2rem;
    }


    #menu{
        position: fixed;
        top: 7rem;
        width: 100vw;
        height: calc(100svh - 7rem);
        background-color: white;
        z-index: 2;
        transform: translate(100%, 0%);
        text-transform: uppercase;

    }
  
    .hide_menu {
      animation: hide_menu 0.6s ease-in forwards;
    }
  
    .show_menu {
      animation: show_menu 0.6s ease-in forwards;
    }
  
    @keyframes hide_menu {
      from {
        transform: translate(0%, 0%);
      }
      to {
        transform: translate(100%, 0%);
      }
    }
  
    @keyframes show_menu {
      from {
        transform: translate(100%, 0%);
      }
      to {
        transform: translate(0%, 0%);
      }
    }

    .menu_mobile{
        text-align: center;
    }

    .menu_mobile h2{
        margin-top: 2rem;
        font-size: 6vw;
        color: #2E2D69;
    }


    .menu_mobile .flags{
        width: 50%;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        justify-content: space-around;
    }

    .menu_mobile .flags img{
        width: 10vw;
    }

    .menu_mobile .logo{
        width: 60%;
        margin: auto;
        margin-top: 2rem;
    }



}
