@font-face {
    font-family: 'tenby_five';
    src: url('./fonts/tenby-five.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'helvetica';
    src: url('./fonts/Helvetica.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

body{
    font-family: 'tenby_five';
    font-size: 20px;
    overflow-x: hidden;
}

.title_seo{
    display: none;
}

